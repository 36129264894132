import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { IndexPage } from "./index"
import VideoCard from "../components/pricing/pricing-cards/Video"
import EbooksCard from "../components/pricing/pricing-cards/Ebooks"
import PricingWrapper from "../components/pricing/PricingWrapper"
import Complete7DaysFreeCard from "../components/pricing/pricing-cards/Complete7DaysFree"
import { scrollById, scroller } from "../utils/scroller"
import List from "../components/list"
import { useRef } from "react"
import classnames from "../helpers/classnames"
import PricingCard from "../components/pricing/PricingCard"
import Complete from "../components/pricing/pricing-cards/Complete"
import LecturesOnePricingCard from "../components/pricing/pricing-cards/LecturesOnePricingCard"
import LecturesTwoPricingCard from "../components/pricing/pricing-cards/LecturesTwoPricingCard"
import CaseStudiesPricingCard from "../components/pricing/pricing-cards/CaseStudiesPricingCard"
import CompleteCard from "../components/pricing/pricing-cards/Complete"
import SliderImgDiederik from "../images/img/testimonials/diederik.jpg"
import SliderImgGeorgedan from "../images/img/testimonials/georgedan.jpg"
import SliderImgVinitasingh from "../images/img/testimonials/vinitasingh.jpg"
import SliderImgLukacko from "../images/img/testimonials/valerianlukacko.jpg"
import cardStyles from "../components/card/card.module.scss"
import Cookies from "js-cookie"
import { parseQuery } from "../helpers/url"
import MainLayout from "../components/Layout/MainLayout"
import NewsletterPopup from "../components/modals/newsletterPopup"
import QuizPopup from "../components/modals/quizPopup"
import ContactPopup from "../components/modals/ContacPopup"
import FreemiumPopup from "../components/modals/FreemiumPopup"
import Navbar from "../components/navbar"
import NavigationItems from "../components/navigation/NavigationItems"
import NavigationItem from "../components/navigation/NavigationItem"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Button from "../components/button"
import HeroVideo from "../video/Hero-video_Speakers.mp4"
import Title from "../components/title"
import SalesBadge150 from "../images/img/sales-page/Badge_150.svg"
import Slider from "react-slick"
import imgRory from "../images/img/sales-page/Rory.png"
import imgSam from "../images/img/sales-page/Sam.png"
import imgSucha from "../images/img/sales-page/Sucha.png"
import imgSilueta from "../images/img/sales-page/silueta.png"
import Sponsors from "../components/Sponsors"
import Section from "../components/Section"
import CourseRating from "../components/CourseRating"
import FlyingShape from "../components/FlyingShape"
import MasterclassAboutIcons from "../components/MasterclassAboutIcons"
import Card from "../components/card"
import CardImgPlay from "../images/img/courses/Play.svg"
import CardImgHandout from "../images/img/courses/Handout.svg"
import CardImgIdeation from "../images/img/courses/Ideation.svg"
import CardImgReading from "../images/img/courses/Reading.svg"
import CardImgCertificate from "../images/img/courses/Certificate.svg"
import Tooltip from "../components/tooltip/Tooltip"
import TestimonialSlider from "../components/TestimonialSlider"
import Testimonial from "../components/Testimonial"
import CustomCard from "../components/pricing/pricing-cards/Custom"
import MasterclassOptionsIcons from "../components/MasterclassOptionsIcons"
import styles from "../styles/page-index.module.scss"
import Reviews from "../components/reviews"
import Footer from "../components/footer"

let testimonialsData = [
  {
    name: "Diederik Gerbranda",
    organisation: "Growth Marketer at Scaleup Marketing",
    img: SliderImgDiederik,
    quote: `It’s full of eye-opening insights and practical examples but most of
    all it‘s a step-by-step guide on how to apply them into practice.`
  },
  {
    name: "George S. Dan",
    organisation: "CEO at User Nudge",
    img: SliderImgGeorgedan,
    quote: `I want to congratulate you for the work you did with Rory, Sam and
            Dan. Everybody in marketing should take this course. I teach and
            practice Behavioral Science and I will let all my students and
            clients know about the course. A job well done indeed!`
  },
  {
    name: "Vinita Singh",
    organisation: "Senior Research Executive at Nielsen",
    img: SliderImgVinitasingh,
    quote: `Perfectly crafted, well-articulated, highly engaging and enriching
            program. This is one of the finest online
            programs I have come across. The case studies and examples are
            elaborated and make it easier to understand
            BE concepts.`
  },
  {
    name: "Valerian Lukacko",
    organisation: "Marketer, Head of Communication at Reflect Festival",
    img: SliderImgLukacko,
    quote: `The best online marketing course I've ever seen. I recommend it to
            anyone who would like to better understand their customers and
            increase sales.`
  }
]

let speakersSettings = {
  dots: true,
  infinite: false,
  autoplay: true,
  slidesToShow: 4,
  draggable: false,
  adaptiveHeight: true,
  speed: 900,
  autoplaySpeed: 2000,
  easing: "swing",
  fade: false,
  pauseOnHover: false,
  prevArrow: <></>,
  nextArrow: <></>,
  appendDots: dots => (
    <div>
      <ul style={{ margin: "0px" }} className="dotsList">
        {dots}
      </ul>
    </div>
  ),
  customPaging: () => (
    <button
      style={{
        padding: "0",
        border: "none",
        outline: "none",
        width: "8px",
        height: "8px"
      }}
      className="dotButton"
    ></button>
  ),
  responsive: [
    {
      breakpoint: 2200,
      settings: {
        autoplay: false,
        infinite: false
      }
    },
    {
      breakpoint: 1026,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: true
      }
    },

    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
  ]
}

let settings = {
  dots: true,
  infinite: false,
  autoplay: true,
  slidesToShow: 4,
  draggable: false,
  adaptiveHeight: true,
  speed: 900,
  autoplaySpeed: 2000,
  easing: "swing",
  fade: false,
  pauseOnHover: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false
      }
    },

    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
}

const tooltipStyle = isHover => {
  return isHover ? cardStyles.tooltipStyles : cardStyles.tooltipHidden
}

const showMoreStyle = showMore => {
  return showMore ? "showMoreItem" : "faderItem"
}

export class IndexPage extends React.Component {
  state = {
    isMenuOpen: false,
    isHover: false,
    showMore: false,
    // Quiz|Newsletter subscription states
    subscribeNewsletterModalIsOpen: false,
    quizModalIsOpen: false,
    ctaButtonAlreadyClicked: Cookies.get("preview_clicked") !== undefined,
    contactModalIsOpen: false,
    freemiumModalIsOpen: false,
    // BETipsPopupModalIsOpen: false,
    // BETipsPopupModalDisabled: Cookies.get("betips_popup_show") !== undefined,
    // mouseYBelow100: false,
    // spent10secs: false,
    ctaLabel: this.props.ctaLabel || "GET FREE PREVIEW",
    christmasModalShow: false,
    navigationData: [
      {
        label: "Masterclass content",
        anchor: true,
        href: "#lessons"
      },
      {
        label: "Instructors",
        anchor: true,
        href: "#speakers"
      },
      {
        label: "Pricing",
        anchor: true,
        href: this.props.pricingHref || "#pricing-complete-masterclass"
      }
    ]
  }

  /* NOTE: old 'on page leaving' betips popup functionality - if needed in the future
  handleMouseMove = e => {
    // change state only on value change for rendering performance
    let oldVal = this.state.mouseYBelow100
    let newVal = e.clientY < 100
    if (oldVal !== newVal) {
      this.setState({
        mouseYBelow100: newVal
      })
    }
  }

  handleMouseLeave = () => {
    if (
      this.state.mouseYBelow100 &&
      !this.state.BETipsPopupModalDisabled &&
      this.state.spent10secs
    ) {
      this.handleOpenBETipsPopupModal()
    }
  }*/

  componentDidMount() {
    let scrollToMasterclassContentChapter = this.props.location.hash.startsWith("#masterclass-content-chapter-")

    if (scrollToMasterclassContentChapter) {
      this.setState({ showMore: true })
      setTimeout(() => {
        scrollById(this.props.location.hash.slice(1), -120)
      }, 0)
    }

    // window.addEventListener("scroll", this.listenScrollEvent)
    document.addEventListener("mouseleave", this.handleMouseLeave)

    if (typeof window !== "undefined") {
      // don't redirect if ncr query param is set to true
      const query = this.props.location.search
      const ncr = parseQuery(query)["ncr"] === "true"
      if (!ncr && this.props.countryRedirect) {
        const API_URL = "https://europe-west1-mindworxacademy.cloudfunctions.net/geolocation"
        fetch(API_URL, { method: "GET" }).then(res => res.json())
          .then(json => {
            if (json.country === "US" && window.location.href.indexOf("us") === -1) {
              window.location.replace(`${this.props.location.origin}/us` + document.location.search)
            } else if (json.country === "GB" && window.location.href.indexOf("uk") === -1) {
              window.location.replace(`${this.props.location.origin}/uk` + document.location.search)
            }
          })
          .catch(_ => {
            //  reject(err)
          })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent)
  }

  handleToggleMenu = () => {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen
    }))
  }

  handleShowMore = () => {
    this.setState(state => ({
      showMore: !state.showMore
    }), () => {
      // scroll to first point, after re-rendering
      window.scrollBy({ top: this.masterclassContentRef.getBoundingClientRect().top - 150 })
    })
  }

  handleOpenSubscribeNewsletterModal = () => {
    this.setState({ subscribeNewsletterModalIsOpen: true })

    // don't shake buttons after popup opened
    this.setState({ ctaButtonAlreadyClicked: true })
    Cookies.set("preview_clicked", true, { expires: 31 })
  }
  handleCloseSubscribeNewsletterModal = () =>
    this.setState({ subscribeNewsletterModalIsOpen: false })

  handleOpenQuizModal = () => this.setState({ quizModalIsOpen: true })
  handleCloseQuizModal = () => this.setState({ quizModalIsOpen: false })

  handleOpenContactModal = () => this.setState({ contactModalIsOpen: true })
  handleCloseContactModal = () => this.setState({ contactModalIsOpen: false })

  handleOpenFreemiumModal = () => this.setState({ freemiumModalIsOpen: true })
  handleCloseFreemiumModal = () => this.setState({ freemiumModalIsOpen: false })

  handleSubscribeNewsletterAfterSubmit = () => {
    this.handleCloseSubscribeNewsletterModal()
    // redirect to experience
    setTimeout(() => {
      window.location.replace(this.props.location.origin + (typeof this.props.newsletterPopupRedirect !== "undefined" ? this.props.newsletterPopupRedirect : "/experience"))
    }, 500)
  }

  handleFreemiumPopupAfterSubmit = () => {
    this.handleCloseFreemiumModal()
    setTimeout(() => {
      window.location.replace(this.props.checkoutUrls.free.url)
    }, 500)
  }

  handleClose = () => {
    this.setState({
      showModal: false
    })
  }

  handleMouseIn() {
    this.setState({ isHover: true })
  }

  handleMouseOut() {
    this.setState({ isHover: false })
  }

  ctaAction = this.props.ctaAction
    ? this.props.ctaAction
    : this.handleOpenSubscribeNewsletterModal

  render() {

    const { isMenuOpen, isHover, showMore } = this.state
    let checkoutUrls = this.props.checkoutUrls

    return (
      <MainLayout>
        {this.props.children}

        <NewsletterPopup
          show={this.state.subscribeNewsletterModalIsOpen}
          handleClose={this.handleCloseSubscribeNewsletterModal}
          handleAfterSubmit={this.handleSubscribeNewsletterAfterSubmit}
        />
        <QuizPopup
          show={this.state.quizModalIsOpen}
          handleClose={this.handleCloseQuizModal}
        />
        <ContactPopup
          show={this.state.contactModalIsOpen}
          handleClose={this.handleCloseContactModal}
        />
        <FreemiumPopup
          show={this.state.freemiumModalIsOpen}
          handleClose={this.handleCloseFreemiumModal}
          handleAfterSubmit={this.handleFreemiumPopupAfterSubmit}
        />
        <Navbar
          hasBackground
          logo="dynamic"
          isMenuOpen={isMenuOpen}
          location={this.props.location}
          onToggleMenu={this.handleToggleMenu}
          discountBanner={this.props.discountBanner}
        >
          <NavigationItems>
            {this.state.navigationData.map(({ anchor = false, href, label }, index) => (
              <NavigationItem
                key={index}
                onToggleMenu={this.handleToggleMenu}
              >
                <AnchorLink
                  to={this.props.location.pathname + href}
                >{label}</AnchorLink>
              </NavigationItem>
            ))}
            <NavigationItem onToggleMenu={this.handleToggleMenu}>
              <a href="https://sso.teachable.com/secure/236806/users/sign_in">Login</a>
            </NavigationItem>
            <NavigationItem onToggleMenu={this.handleToggleMenu}>
              <Button
                id="cta-popup-1"
                size="header"
                onClick={this.ctaAction}
                shake={!this.state.ctaButtonAlreadyClicked}
              >{this.state.ctaLabel}</Button>
            </NavigationItem>
          </NavigationItems>
        </Navbar>
        <div style={{ position: "relative", height: "100%" }}>
          <div className="box_bg first_slide">
            <div className="video_cont">
              <div style={{ height: "100%" }}>
                <video
                  preload="auto"
                  loop
                  muted
                  playsInline
                  autoPlay
                  style={{ minWidth: "100%", minHeight: "100%" }}
                >
                  <source src={HeroVideo} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="box_bg_sizer"></div>
            <div className="box_bg_inner">
              <div className="box_bg_content" style={{ position: "relative" }}>
                <Title> Behavioral Economics & Psychology in Marketing </Title>
                <ul className="list_inline">
                  <li>
                    <strong>Online masterclass</strong>
                    <br />
                    Which will set you apart
                  </li>
                  <li>Radically change how you think of marketing</li>
                  <li>Measurably improve your results</li>
                </ul>
                <a data-scroll-to="#pricing" href="#pricing">
                  <img
                    alt=""
                    className="hidden box_bg_content_badge"
                    src={SalesBadge150}
                    title=""
                  />
                </a>
                <div className={classnames(
                  "sm:mb-16 mb-80",
                  "buttons"
                )}>
                  <Button id="cta-popup-2" size="small" onClick={this.ctaAction}>
                    {this.state.ctaLabel}
                  </Button>
                </div>

                <div style={{ position: "relative" }}>{this.props.countdown}</div>

                <Slider {...settings}>
                  <div className="persons__person">
                    <div className="persons__person__name">Rory Sutherland</div>
                    <div className="persons__person__desc">Ogilvy Group</div>
                    <div className="persons__person__photo">
                      <img
                        alt="Photo of Rory Sutherland"
                        className="img-responsive"
                        src={imgRory}
                        title=""
                      />
                      <svg height="140" width="140">
                        <circle
                          cx="70"
                          cy="70"
                          fill="transparent"
                          r="70"
                          stroke="#FF1A2A"
                          strokeWidth="3"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                  <div className="persons__person">
                    <div className="persons__person__name">Sam Tatam</div>
                    <div className="persons__person__desc">Ogilvy UK</div>
                    <div className="persons__person__photo">
                      <img
                        alt="Photo of Sam Altman"
                        className="img-responsive"
                        src={imgSam}
                        title=""
                      />
                      <svg height="140" width="140">
                        <circle
                          cx="70"
                          cy="70"
                          fill="transparent"
                          r="70"
                          stroke="#FF1A2A"
                          strokeWidth="3"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                  <div className="persons__person">
                    <div className="persons__person__name">Matej Sucha</div>
                    <div className="persons__person__desc">MINDWORX</div>
                    <div className="persons__person__photo">
                      <img
                        alt="Photo of Matej Sucha"
                        className="img-responsive"
                        src={imgSucha}
                        title=""
                      />
                      <svg height="140" width="140">
                        <circle
                          cx="70"
                          cy="70"
                          fill="transparent"
                          r="70"
                          stroke="#FF1A2A"
                          strokeWidth="3"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                  <div className="persons__person" id="surprise-guest-wrapper">
                    <div className="persons__person__name">Surprise guest</div>
                    <div className="persons__person__desc">Duke University</div>
                    <div className="persons__person__photo">
                      <img
                        alt="Surprise Guest"
                        className="img-responsive"
                        id="surprise-guest-image"
                        src={imgSilueta}
                        title=""
                      />
                      <div
                        className="open_popup-surprise-guest"
                        tabIndex="0"
                      >
                        <svg height="140" width="140">
                          <circle
                            cx="70"
                            cy="70"
                            fill="transparent"
                            r="70"
                            stroke="#FF1A2A"
                            strokeWidth="3"
                          />
                        </svg>
                        <p className="person__surprise--back">
                          Who's our surprise guest? Let us know and get 15% discount.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div
            className="persons-wrapper"
            style={{
              width: "100%",
              backgroundSize: "100%",
              backgroundPositionY: "15px",
              height: "80px"
            }}
          ></div>
        </div>
        <section
          className="company-logos"
          style={{ maxWidth: "1350px", margin: "0 auto" }}
        >
          <Sponsors />
        </section>

        <Section style={{ position: "relative", overflow: "hidden", paddingTop: 0 }}>

          <AnchorLink className={"no-link"} to={this.props.location.pathname + "#reviews"}>
            <CourseRating />
          </AnchorLink>

          <div style={{ position: "relative" }} className="wrapper page_content">
            <FlyingShape
              bottom={0}
              right={0}
              offsetX={800}
              factorX={{ xs: 0, sm: 0, md: 0.45, lg: 0.45 }}
              color="#0017be"
            />
            <div style={{ position: "relative" }}>
              <h2>What’s this Masterclass about?</h2>
              <p>
                This Masterclass reveals exactly how to use insights from{" "}
                <strong>
                  4 decades of research in consumer psychology and behavioral
                  economics to increase conversions.
                </strong>
              </p>
              <p>
                You’ll get a <strong>step-by-step framework</strong> on how to
                apply 24 most powerful psychological principles into your ads,
                landing pages, emails, sales funnels and everything in between.
              </p>
            </div>
          </div>

          <MasterclassAboutIcons className={classnames(
            "mt-64",
            "md:mt-32",
            "sm:mt-16"
          )} />
        </Section>

        <section className="section_course" id="course">
          <div className="wrapper2 rel" id="lessons">
            <div>
              <div className="line page_content page_content--header page_content--center">
                <h2>What you get in the Masterclass</h2>
                <p>
                  <strong>Step-by-step framework</strong> on how to apply{" "}
                  <strong>
                    24 most powerful principles from consumer psychology
                  </strong>{" "}
                  and behavioral economics to increase conversions:
                </p>
              </div>
              <div className="line page_content page_content--center">
                <div>
                  <Card>
                    <div>
                      <div className={cardStyles.image}>
                        <img
                          className={cardStyles.img}
                          alt=""
                          src={CardImgPlay}
                          title=""
                        />
                      </div>
                      <p className={cardStyles.text}>
                        8 hours
                        <br />
                        of video lectures
                      </p>
                      <div className={cardStyles.cardBack}>
                        <p>
                          The core format and basis for the whole Masterclass.
                          Each video is crammed full of valuable, instantly
                          applicable info from 4 world-class experts.
                        </p>
                      </div>
                    </div>

                    <div>
                      <div className={cardStyles.image}>
                        <img
                          className={cardStyles.img}
                          alt=""
                          src={CardImgHandout}
                          title=""
                        />
                      </div>
                      <p className={cardStyles.text}>
                        14 eBooks
                        <br />
                        (450+ pages in total)
                      </p>
                      <div className={cardStyles.cardBack}>
                        <p>
                          Custom-created for this Masterclass. It adds a wealth
                          of valuable insights to the video lectures — including
                          useful tips and fun facts that add depth and richness
                          to your understanding of consumer psychology.
                        </p>
                      </div>
                    </div>

                    <div>
                      <div className={cardStyles.image}>
                        <img
                          className={cardStyles.img}
                          alt=""
                          src={CardImgIdeation}
                          title=""
                        />
                      </div>
                      <p className={cardStyles.text}>
                        ADAPT framework & Ideation Prompts
                      </p>
                      <div className={cardStyles.cardBack}>
                        <p>
                          Step-by-step framework ADAPT on how to apply behavioral insights and a set of guiding
                          questions related to each principle. These questions will direct you towards solutions you
                          wouldn’t otherwise think of.
                        </p>
                      </div>
                    </div>

                    <div>
                      <div className={cardStyles.image}>
                        <img
                          className={cardStyles.img}
                          alt=""
                          src={CardImgReading}
                          title=""
                        />
                      </div>
                      <p className={cardStyles.text}>60+ Case Studies</p>
                      <div className={cardStyles.cardBack}>
                        <p>
                          Each principle is illustrated by
                          a number of business
                          examples and case studies.
                          This way you'll immediately
                          see how the principles can be
                          applied and what results
                          they bring.
                        </p>
                      </div>
                    </div>

                    <div>
                      <div className={cardStyles.image}>
                        <img
                          className={cardStyles.img}
                          alt=""
                          src={CardImgCertificate}
                          title=""
                        />
                      </div>
                      <p className={cardStyles.text}>
                        Official Masterclass certificate
                      </p>
                      <div className={cardStyles.cardBack}>
                        <p>
                          Another way to highlight your new skills (and signal your upgraded worth to your clients,
                          customers, colleagues, and bosses), issued upon completing the Masterclass and passing the
                          final quiz.
                        </p>
                      </div>
                    </div>
                  </Card>
                  <div
                    className="tooltip-wrapper"
                    style={{ marginBottom: "30px" }}
                  >
                    <div>
                      <strong>
                        Lifetime access — Learn at your own pace — full 14-day
                        money-back guarantee
                        <Tooltip
                          content={
                            <span>
                              If you don’t like the Masterclass let us know at <a
                              className="tooltip-link"
                              href="mailto:moneyback@mindworxacademy.com"
                              style={{ color: "#FF1A2A" }}
                            >moneyback@mindworxacademy.com</a> and with one click we’ll send all your money back, no questions asked. An immediate 14-day money-back guarantee to all our students is also a condition of Teachable - the platform on which this Masterclass was built.
                          </span>
                          }
                        >
                        <span
                          className="masterclass-options__info-icon"
                        />
                        </Tooltip>
                      </strong>
                    </div>
                    <p></p>
                    <div className={tooltipStyle(isHover)}>
                      <div
                        onClick={this.handleMouseOut.bind(this)}
                        className={cardStyles.tooltipCloseBtn}
                      >
                        &#10005;
                      </div>
                      If you don’t like the Masterclass let us know at{" "}
                      <a
                        className="tooltip-link"
                        href="mailto:moneyback@mindworxacademy.com"
                        style={{ color: "#FF1A2A" }}
                      >
                        moneyback@mindworxacademy.com
                      </a>{" "}
                      and with one click we’ll send all your money back, no
                      questions asked. An immediate 14-day money-back guarantee
                      to all our students is also a condition of Teachable - the
                      platform on which this Masterclass was built.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="buttons section_course__btns"
              style={{ bottom: "inherit" }}
            >
              <Button id="cta-popup-3" size="large" onClick={this.ctaAction}>
                {this.state.ctaLabel}
              </Button>
            </div>
          </div>
        </section>
        <section
          className="shape_cont section_what"
          style={{ marginTop: "40px" }}
        >
          <div className="shape_cont_inner wrapper page_content">
            <h2>Who's it for?</h2>
            <p>
              <strong>It's right for you if...</strong>
            </p>
            <List type="circle">
              <span>
                You're a <strong>marketer</strong> and you want to <strong>increase conversion rates</strong> of landing pages and sales funnels
              </span>
              <span>
                You're a freelance <strong>consultant</strong> and you want to <strong>get ahead of the competition</strong> and bring new insights to
                your clients
              </span>
              <span>
                You need to <strong>design</strong> performing <strong>B2B or B2C marketing and sales strategies</strong>
              </span>
              <span>
                You <strong>write copy</strong> for websites, direct emails, online ads, etc
              </span>
              <span>
                You’re a <strong>behavioral economics practitioner</strong> and you look for new case studies and inspirations
              </span>
            </List>
          </div>
        </section>
        <section className="shape_cont section_what">
          <div className="shape_cont_inner wrapper page_content">
            <h2>Why “Behavioral Economics” in marketing?</h2>
            <p>
              Simply put, behavioral economics is an entirely practical field
              about{" "}
              <strong>
                how people make decisions and what drives their behavior.
              </strong>{" "}
              It gives you the tools to understand, predict, and influence them.
            </p>
            <p>
              As long as you do your business with humans, you will forever
              benefit from this knowledge.
            </p>
            <p>Thanks to this Masterclass, you will learn:</p>
            <List type="circle">
              <p>
                That 90 — 95% of purchase decisions are subconscious. And how to
                shape those decisions.
              </p>
              <p>
                What’s going on in your customers’ minds. And how you can easily
                influence what they buy, where they click or how they react.
              </p>
              <p>
                How to use the tested & proven findings to improve your results.
              </p>
            </List>
          </div>
        </section>
        <section className="shape_cont section_speakers" id="speakers">
          <div className="wrapper2 rel" style={{ marginBottom: "50px" }}>
            <FlyingShape
              top={0}
              left={0}
              offsetX={-2078}
              factorX={{ xs: -0.35, sm: -0.35, md: -0.55, lg: -0.55 }}
              shape="triangle"
              color="#EBF807"
            />

            <div className="page_content">
              <h2>Who are your instructors?</h2>
              <p>
                In this Masterclass you get access to four of the{" "}
                <b>world’s leading experts in behavioral economics </b>today.
                And their combined expertise which they’ve spent decades to
                acquire, test and perfect — while shaping buying decisions for
                some of the biggest companies out there.
              </p>
            </div>
            <div className="sliderWrapper">
              <Slider {...speakersSettings}>
                <>
                  <div className="speaker-item">
                    <img
                      alt="photo of instructor Rory"
                      src={imgRory}
                      title="photo of instructor Rory"
                      className="speaker_image"
                    />
                    <div className="speaker-decr">
                      <h3 className="speaker-decr_title">Rory Sutherland</h3>
                      <p className="speaker-decr_text--blue">
                        Vice Chairman,
                        <br />
                        Ogilvy Group UK
                      </p>
                      <p className="speaker-decr_text">
                        One of the most influential figures in modern marketing
                        and a pioneer in the use of psychology and behavioral
                        economics in marketing, with extensive experience of
                        working for the world’s biggest brands.
                      </p>
                    </div>
                  </div>
                </>

                <>
                  <div className="speaker-item">
                    <img
                      alt="photo of instructor Sam"
                      src={imgSam}
                      title="photo of instructor Sam"
                      className="speaker_image"
                    />
                    <div className="speaker-decr">
                      <h3 className="speaker-decr_title">Sam Tatam</h3>
                      <p className="speaker-decr_text--blue">
                        Behavioral Strategy Director,
                        <br />
                        Ogilvy Consulting UK
                      </p>
                      <p className="speaker-decr_text">
                        Award-winning strategist, psychologist, and advertising
                        practitioner who develops interventions and shapes
                        communication for some of the world’s most influential
                        brands and organisations.
                      </p>
                    </div>
                  </div>
                </>
                <>
                  <div className="speaker-item">
                    <img
                      alt="photo of instructor Sucha"
                      src={imgSucha}
                      title="photo of instructor Sucha"
                      className="speaker_image"
                    />
                    <div className="speaker-decr">
                      <h3 className="speaker-decr_title">Matej Sucha</h3>
                      <p className="speaker-decr_text--blue">
                        Founder and CEO,
                        <br />
                        MINDWORX Behavioral Consulting
                      </p>
                      <p className="speaker-decr_text">
                        Recognized speaker and expert in applying behavioral
                        economics and consumer psychology in business, with
                        notable expertise in working with both large corporates
                        and SMBs.
                      </p>
                    </div>
                  </div>
                </>
                <>
                  <div className="speaker-item">
                    <img
                      alt="photo of the secret instructor"
                      src={imgSilueta}
                      title="photo of the secret instructor"
                      className="imageSurprise"
                      className="speaker_image"
                    />
                    <div className="speaker-decr">
                      <h3 className="speaker-decr_title">Surprise guest</h3>
                      <p className="speaker-decr_text--blue">
                        Professor of Behavioral Economics and Psychology
                      </p>
                      <p className="speaker-decr_text">
                        One of the most famous behavioral economists in the
                        world, renowned speaker and best-selling author of
                        several books.
                      </p>
                    </div>
                  </div>
                </>
              </Slider>
            </div>
          </div>
        </section>
        <TestimonialSlider>
          {testimonialsData.map((item, index) => {
            return (
              <Testimonial
                key={index}
                {...item}
              />
            )
          })}
        </TestimonialSlider>
        <section
          className="shape_cont section_what section_what_with_top_space"
          style={{ paddingTop: "40px" }}
        >
          <div className="shape_cont_inner wrapper page_content">
            <h2>Masterclass content in detail</h2>
            <p>
              The 8 hours of content are divided into 7 chapters.
              <br />
              Each chapter contains several <strong>video lectures</strong>{" "}
              accompanied by knowledge crammed{" "}
              <strong>eBooks</strong>.
            </p>
            <p>How to get the most out of the Masterclass?</p>
            <List type="circle">
              <>
                <strong>First</strong> watch the video lecture (10 — 20 minutes)
              </>
              <>
                <strong>Then</strong> read the corresponding eBook (20 — 30
                pages).
              </>
            </List>
            <p></p>
            <p>
              <strong>Here are the 7 chapters:</strong>
            </p>
            <p></p>
          </div>
        </section>
        <section className="shape_cont section_will">
          <FlyingShape
            top={173}
            right={0}
            offsetX={1321}
            factorX={{ xs: 0, sm: 0, md: 0.2, lg: 0.2 }}
            color="#0017be"
          />

          <div className="shape_cont_inner wrapper">
            <div className="box_steps cf">
              <div className="line page_content padding-left-zero">
                <div id="masterclass-content-chapter-1" ref={ref => this.masterclassContentRef = ref}
                     className="line page_content" data-num="01">
                  <h3 className="faderTitle">
                    What is BE and why does it matter for marketers?
                  </h3>
                  <p>
                    More and more, marketing and communication are becoming a science. A science about what makes
                    customers tick, how they behave and make decisions. Rory explains to you why marketers must learn
                    about behavioral economics and why standard approaches to marketing and advertising are slowly
                    dying.
                    <br />
                    <br />
                    This chapter grants you a bunch of surprising examples, astonishing case studies, and eye-opening
                    insights, all wrapped in Rory’s unmistakable sense of humor.
                  </p>
                  {showMore ? (
                    <div
                      className="lock-fader"
                      style={{ background: "none" }}
                      id="fader-paragraph"
                    ></div>
                  ) : (
                    <div className="lock-fader" id="fader-paragraph"></div>
                  )}
                </div>
                {showMore ? (
                  <div id="show-more-wrapper" className="showMoreWrapper">
                    <div className={showMoreStyle(showMore)} data-num="02">
                      <h3 className="faderTitle">
                        A - Analyse the decision-making context
                      </h3>
                      <p>
                        Matej shows you the most common mistakes marketers make when they approach a project. You obtain
                        the necessary tools to discover why customers aren’t doing what you want them to do.
                        <br />
                        <br />
                        He takes you step-by-step through the process of analyzing the decision-making context. This
                        crucial part must be made before you start coming up with ideas. Otherwise, all the efforts and
                        solutions you come up with might be in vain. Which would be rather unfortunate. Especially since
                        there is a better way.
                      </p>
                    </div>
                    <div className={showMoreStyle(showMore)} data-num="03">
                      <h3 className="faderTitle">D - Drivers of behavior</h3>
                      <p>
                        In this section of the course, you get your hand on powerful, scientifically proven behavioral
                        tools that will enable you to change the behavior and decisions of your customer.
                        <br />
                        <br />
                        Get prepared to be surprised. Matej reveals to you the surprising psychological principles which
                        all clients were astonished to learn. He shares how unimportant it might be to talk about the
                        benefits of your product or how simply changing the order of information you give can work
                        wonders.
                        <br />
                        <br />
                        He unveils how he and his team used these principles to boost conversion by 132%, increase the
                        effectiveness of a campaign by 55%, or improved CTRs by 57%. Not half bad, actually!
                        <br />
                        <br />
                        Thanks to this chapter, you can now master principles like <b>Uncertainty, Perceived Effort,
                        Reciprocity, Social Proof,</b> and <b>Loss Aversion.</b>
                      </p>
                    </div>
                    <div id="masterclass-content-chapter-4" className={showMoreStyle(showMore)} data-num="04">
                      <h3 className="faderTitle">A - Choice architecture</h3>
                      <p>
                        Sam explores one of the most powerful ways to shape the behavior of your customer — <b>changing
                        choice architecture</b>. The way the decision-making process is built, choices are presented and
                        when you attract people’s attention is crucial.
                        <br />
                        <br />
                        Are you curious what tiny change on a website brought 300 million in revenues? Sam gives you the
                        answer and explains why more choice for customers can be literally deadly for your business and
                        what to do about it. Now you understand how increasing friction for your customers can be a
                        surprising goldmine.
                        <br />
                        <br />
                        You will learn all there is to know acquire everything needed about the following concepts:{" "}
                        <b>Choice Overload, Defaults, Friction,</b> and <b>Salience</b>.
                      </p>
                    </div>
                    <div className={showMoreStyle(showMore)} data-num="05">
                      <h3 className="faderTitle">
                        P - Perception of price and product
                      </h3>
                      <p>
                        Our secret guest lecturer explains that people don’t know how much they are willing to pay for
                        products or services and what power that gives you. You get to see that customers cannot
                        evaluate prices and offers in a vacuum, that’s why the context is crucial.
                        <br />
                        <br />
                        You get armed with all the powerful tools to change the decision-making context and thus the
                        perception of prices, products, and fairness of your offers.
                        <br />
                        <br />
                        Get ready to be astonished. We will explain why customers might be happier if they wait longer,
                        that a random number can make them pay more or that an irrelevant offer which no one chooses can
                        be a game-changer.
                        <br />
                        <br />
                        You get a step-by-step guide on how to best use principles such as <b>Fairness, Middle Option,
                        Anchoring, Power of Free</b> and <b>Decoy</b>.
                      </p>
                    </div>
                    <div className={showMoreStyle(showMore)} data-num="06">
                      <h3 className="faderTitle">T - Test and iterate</h3>
                      <p>
                        The last letter of the ADAPT framework talks about the last essential step on your way to being
                        able to design solutions which really work.
                        <br />
                        <br />
                        It’s tough to predict which of your ideas will work best. Behavioral economics gives you the
                        tools to create amazing solutions, but only testing them will clearly show which one is the
                        winner.
                        <br />
                        <br />
                        Matej explains how you should test, who to test on, and what to do with the results.
                      </p>
                    </div>
                    <div id="masterclass-content-chapter-7" className={showMoreStyle(showMore)} data-num="07">
                      <h3 className="faderTitle">Case studies</h3>
                      <p>
                        Are you curious to see the power of Behavioral Economics applied to real-world problems? Get
                        inspired by a carefully-selected collection of case studies.
                        <br/>
                        <br/>
                        Rory, Sam, Matej, and the 4th secret lecturer explain the use of behavioral insights in product
                        design, direct mail campaigns, website optimization, behavior change, and more.
                        <br/>
                        <br/>
                        There’s nothing quite like seeing all that knowledge applied and working in a real-life context.
                        These hand-picked real-life examples teach you how to use behavioral economics to solve specific
                        challenges. Join our 4 lecturers who will walk you through 11 case studies in great detail (and
                        in some cases with humor as well).
                        <br/>
                        <br/>
                        This masterclass provides both loads of inspiration as well as a great context to help you
                        absorb the lessons and use them correctly in your own practice.
                      </p>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    textAlign: "center",
                    position: "relative",
                    bottom: "5px"
                  }}
                >
                  <Button
                    size="showMore"
                    onClick={this.handleShowMore}
                  >
                    {showMore ? "SHOW LESS" : "SHOW MORE"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Section id="masterclass-options-section" title={<>Masterclass options</>}>

          {this.props.b_pricing_text &&
          this.props.b_pricing_text
          }

          <AnchorLink className={"no-link"} to={this.props.location.pathname + "#reviews"}>
            <CourseRating />
          </AnchorLink>

          {
            this.props.pricing ||
            <PricingWrapper>
              <VideoCard
                oldPrice={checkoutUrls.video.oldPrice}
                price={checkoutUrls.video.price}
                href={checkoutUrls.video.url}
              />
              <CompleteCard
                moneyBackGuarantee
                id="pricing-complete-masterclass"
                oldPrice={checkoutUrls.complete.oldPrice}
                price={checkoutUrls.complete.price}
                href={checkoutUrls.complete.url}
              />
              <CustomCard
                handleButtonClick={this.handleOpenContactModal}
              />
              <EbooksCard
                oldPrice={checkoutUrls.ebook.oldPrice}
                price={checkoutUrls.ebook.price}
                href={checkoutUrls.ebook.url}
              />
            </PricingWrapper>
          }

          <div
            className="mx-auto text-center mt-64"
          >
            Interested in <strong>more than one license</strong>?
            Click <strong onClick={this.handleOpenContactModal}
                          className={classnames("cursor-pointer underlined:hover color-red")}>here</strong> for a custom
            price.
          </div>

          <MasterclassOptionsIcons className={classnames("mt-64 md:mt-32")} />

          <div className={styles.ctaPopup4Wrapper}>
            <Button
              id="cta-popup-4"
              outline
              size="large"
              onClick={this.ctaAction}
            >
              {this.state.ctaLabel}
            </Button>
          </div>

          <div
            className={classnames("mx-auto text-center mt-64")}
            style={{ maxWidth: "630px" }}
          >
            Are you a student, NGO or charity and would love to join — but have
            no budget for it? Request one of the MINDWORX Academy Scholarships <a className="styled"
                                                                                  href="mailto:info@mindworxacademy.com">here</a>
            . We make it a point to contribute to worthy causes. Let us know
            yours and we’ll get back to you.
          </div>
        </Section>

        <Section id="reviews" title={<>What people are saying</>}>
          <Reviews key={"reviews"} />
        </Section>

        <Footer />
      </MainLayout>
    )
  }
}

// const discountBanner = {
//   show: true,
//   pages: ["/b", "/b/"],
//   expires: "Nov 27 2020 23:59:59 GMT+0100 (Central European Standard Time)",
//   text: "Black Friday sale - get <b>60% off</b>! | %dd %hh %mmin %ss left</a>",
//   formatted: false,
//   onClick: event => {
//     if (event.target.tagName !== "A") {
//       event.preventDefault();
//       let target = document.getElementById("pricing-complete-masterclass");
//       scroller(target, -180);
//     }
//   }
// };

const Page = ({ location, ...rest }) => {
  const siteMetadata = useStaticQuery(query).site.siteMetadata
  const { b } = siteMetadata.pricingConfiguration
  const indexPageRef = useRef()

  const scrollToChapter = (e, chapterN) => {
    e.preventDefault()
    indexPageRef.current.setState({ showMore: true })
    setTimeout(() => {
      scrollById(`masterclass-content-chapter-${chapterN}`, -120)
    }, 100)
  }

  return (
    <IndexPage
      ref={indexPageRef}
      location={location}
      checkoutUrls={b}
      newsletterPopupRedirect={"/experience-b"}
      pricingHref="#masterclass-options-section"
      countryRedirect={false}
      b_pricing_text={
        <div className={classnames("mx-auto")} style={{ maxWidth: "674px" }}>
          <p>You can either enroll in the&nbsp;<b>Complete Masterclass</b>&nbsp;or
            in one of the three parts which constitute it.</p>

          <List type="circle" className={classnames("my-16")}>
            <span><b>Lectures #1:</b> includes chapters 1, 2 and 3 (see <a className={classnames("underlined")} href="#"
                                                                           onClick={e => scrollToChapter(e, 1)}>here</a>)</span>
            <span><b>Lectures #2:</b> includes chapters 4, 5 and 6 (see <a className={classnames("underlined")} href="#"
                                                                           onClick={e => scrollToChapter(e, 4)}>here</a>)</span>
            <span><b>Case studies:</b> includes chapter 7 (see <a className={classnames("underlined")} href="#"
                                                                  onClick={e => scrollToChapter(e, 7)}>here</a>)</span>
          </List>

          <p>
            It’s OK to enroll part by part. We just recommend that you start
            with Lectures #1, then Lectures #2 and finish with Case Studies.
          </p>
        </div>
      }
      pricing={
        <PricingWrapper>
          <LecturesOnePricingCard
            buttonId="lectures-1-buy-b"
            big
            moneyBackGuarantee
            oldPrice={b.lectures1.oldPrice}
            price={b.lectures1.price}
            href={b.lectures1.url}
          />

          <LecturesTwoPricingCard
            buttonId="lectures-2-buy-b"
            big
            moneyBackGuarantee
            oldPrice={b.lectures2.oldPrice}
            price={b.lectures2.price}
            href={b.lectures2.url}
          />

          <CaseStudiesPricingCard
            buttonId="case-studies-buy-b"
            big
            moneyBackGuarantee
            oldPrice={b.caseStudies.oldPrice}
            price={b.caseStudies.price}
            href={b.caseStudies.url}
          />

          <CompleteCard
            buttonId="main-complete-buy-b"
            bVersion
            moneyBackGuarantee
            id="pricing-complete-masterclass"
            oldPrice={b.complete.oldPrice}
            price={b.complete.price}
            href={b.complete.url}
          />

        </PricingWrapper>
      }
    />
  )
}

const query = graphql`
query
{
  site
  {
    siteMetadata
    {
      pricingConfiguration
      {
        b
        {
          lectures1
          {
            price
            oldPrice
            url
          }
          lectures2
          {
            price
            oldPrice
            url
          }
          caseStudies
          {
            price
            oldPrice
            url
          }
          complete
          {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`


export default Page
